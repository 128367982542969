<template>
  <v-row no-gutters>
    <v-col>
      <v-btn-toggle
        v-model="$store.state.app.readingLayout" 
        color="primary" 
        mandatory 
        class="mb-2"
        :dense="compact"
      >
        <v-btn value="default" @click="$store.commit('app/setReadingLayout', 'default')">Default</v-btn>
        <v-btn value="shlok" @click="$store.commit('app/setReadingLayout', 'shlok')">Shlok</v-btn>
      </v-btn-toggle>
      <div class="ma-0 caption">Set preffered reading layout</div>
      <span v-if="$store.state.app.debug">$store.state.app.readingLayout={{ $store.state.app.readingLayout }}</span>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ReadingLayout',
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  }
}
</script>