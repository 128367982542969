<template>
  <div>
    <v-btn-toggle 
      v-model="$store.state.app.globalTheme" 
      color="primary" 
      mandatory 
      class="mb-2"
      :dense="compact"
    >
      <v-btn value="light" @click="$store.commit('app/setGlobalTheme', 'light')">Light</v-btn>
      <v-btn value="dark" @click="$store.commit('app/setGlobalTheme', 'dark')">Dark</v-btn>
    </v-btn-toggle>
  </div>
</template>
<script>
export default {
  name: 'Theme',
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  }
}
</script>