<template>
  <v-card>
    <v-card-title>Application Settings</v-card-title>
    <v-card-text>
      <DebugSetting />
      <Theme />
      <ReadingHelper />
      <ReadingLayout />
      <ReadingLanguage />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import ReadingHelper from '@/components/settings/ReadingHelper'
import ReadingLayout from '@/components/settings/ReadingLayout'
import DebugSetting from '@/components/settings/DebugSetting'
import ReadingLanguage from '@/components/settings/ReadingLanguage.vue'
import Theme from '@/components/settings/Theme.vue'

export default {
  name: 'SettingsPage',
  components: {
    ReadingHelper,
    ReadingLayout,
    ReadingLanguage,
    DebugSetting,
    Theme
  }
}
</script>