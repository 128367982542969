<template>
  <v-row no-gutters>
    <v-col>
      <v-switch
        v-model="helperEnable"
        label="Enable Helper"
        hint="Enabling helper will provide you language support for non-english languages"
        :persistent-hint="compact"
        :dense="compact"
      ></v-switch>
      <span v-if="$store.state.app.debug">$store.state.app.helperEnable={{ $store.state.app.helperEnable }}</span>
    </v-col>
    <v-col v-if="$store.state.app.helperEnable">
      <v-btn-toggle
        v-model="$store.state.app.helperTransliteration" 
        color="primary" 
        mandatory 
        class="mb-2"
        :dense="compact"
      >
        <v-btn value="latin" @click="$store.commit('app/setHelperTransliteration', 'latin')">Latin</v-btn>
        <v-btn value="phonetic" @click="$store.commit('app/setHelperTransliteration', 'phonetic')">Phonetic</v-btn>
      </v-btn-toggle>
      <div v-if="!compact" class="ma-0 caption">Set preffered helper language phonetic or latin</div>
      <span v-if="$store.state.app.debug">$store.state.app.helperTransliteration={{ $store.state.app.helperTransliteration }}</span>
    </v-col>
    <v-col v-if="$store.state.app.helperEnable">
      <v-btn-toggle
        v-model="$store.state.app.helperLayout" 
        color="primary" 
        mandatory 
        class="mb-2"
        :dense="compact"
      >
        <v-btn value="side" @click="$store.commit('app/setHelperLayout', 'side')">Side By Side</v-btn>
        <v-btn value="under" @click="$store.commit('app/setHelperLayout', 'under')">Under Text</v-btn>
      </v-btn-toggle>
      <div v-if="!compact" class="caption">Set preffered way to display helper text</div>
      <span v-if="$store.state.app.debug">$store.state.app.helperLayout={{ $store.state.app.helperLayout }}</span>
    </v-col>
  </v-row>
<!-- @change="" -->
</template>
<script>
export default {
  name: 'ReadingHelper',
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    helperEnable: {
      get () { return this.$store.state.app.helperEnable },
      set (v) { 
        this.$store.commit('app/setHelperEnable')
      }
    }
  }
}
</script>