<template>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          v-model="readingLanguageOrder"
          hint="A comma separated string of languageId's, this will be the preffered order in which translations are displayed"
          :persistent-hint="compact"
          :dense="compact"
        />
        <div v-if="compact" class="ma-0 caption">A comma separated string of languageId's, this will be the preffered order in which translations are displayed</div>
        <span v-if="$store.state.app.debug">$store.state.app.readingLanguageOrder={{ $store.state.app.readingLanguageOrder }}</span>
      </v-col>
    </v-row>
  </template>
  <script>
  export default {
    name: 'ReadingLanguage',
    props: {
      compact: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      readingLanguageOrder: {
        get () { 
          const i = this.$store.state.app.readingLanguageOrder
          return Array.isArray(i) ? i.join(',') : i
        },
        set (v) { 
          this.$store.commit('app/setReadingLanguageOrder', v.split(','))
        }
      }
    }
  }
  </script>