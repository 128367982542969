<template>
	<div>
		<v-switch
			v-model="debug"
			label="Enable application debug"
			hint="Enabling debug show verbose details across the whole application"
			persistent-hint
			:dense="compact"
		></v-switch>
		<span v-if="$store.state.app.debug">$store.state.app.debug={{ $store.state.app.debug }}</span>
  </div>
</template>
<script>
export default {
	name: 'DebugSetting',
	props: {
		compact: {
			type: Boolean,
			default: false
		}
	},
	computed: {
    debug: {
      get () { return this.$store.state.app.debug },
      set (v) { this.$store.commit('app/toggleDebug') }
    }
  }
}
</script>